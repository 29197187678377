import React from "react"
import Jumbotron from "src/components/jumbotron"
import Layout from "src/components/layout"
import { Box, Grid } from "@material-ui/core"
import TitleStrip from "src/components/marketing/title-strip"
import TestCodeInput from "src/components/test-codes-input"
import SEO from "src/components/seo"
import "src/styles/pages/test-codes-page.scss"
import getRegistrationCode from "src/helpers/get-registration-code.js"
import Dropdown from "src/components/dropdown"
import blue from "../../static/assets/img/testcodes/Wellbeing_background.png"

export default function TestCodesPage() {
  const REGISTRATION_CODE = getRegistrationCode(
    process.env.GATSBY_DENNIS_HOT_LEAD
  )

  return (
    <Layout className="test-codes-root">
      <TitleStrip
        logos={[
          "https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/logo@2x.png",
          "/assets/img/logos/familienservice.png",
        ]}
        links={["/", "https://familienservice.de"]}
      />
      <Jumbotron
        className="marketing-jumbotron"
        backgroundImage={blue}
        section={{
          image: "/assets/img/testcodes/Network_background.png",
          title: "Gesundheitsprävention auf einem neuen Level",
          description:
            "Mentales Wohlbefinden und ganzheitliche Gesundheit für Ihre Mitarbeitenden. Zusammen mit dem pme Familienservice hebt Mindance die Gesundheitsprävention durch ein einzigartiges Blended Learning Konzept auf ein neues Level.",
          subDescription:
            "Fordern Sie hier Ihren Test-Code für die Mindance App an und lernen Sie das Gesamtangebot im Rahmen eines unverbindlichen Präsentationstermins genauer kennen.",
        }}
      >
        <TestCodeInput short registrationCode={REGISTRATION_CODE} />
      </Jumbotron>
      <SEO
        title="Testcode Page"
        description="Fordern Sie hier Ihren Test-Code für die Mindance App an und lernen Sie das Gesamtangebot im Rahmen eines unverbindlichen Präsentationstermins genauer kennen."
        image="/assets/img/testcodes/Network_background.png"
      />
      <Box
        component="section"
        item
        lg={12}
        md={12}
        display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
      >
        <h2>
          Ein ganzheitliches Präventionsangebot für körperlich und mental starke
          Mitarbeiter
        </h2>
      </Box>
      <Grid container direction="row" justify="center" alignItems="center">
        <Box
          component={Grid}
          item
          lg={4}
          md={4}
          display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
        >
          <div className="drop-down-card-right">
            <Dropdown
              anchor={<span className={"drop-down"}>{"Mentale Stärke"}</span>}
            />
          </div>
          <div className="drop-down-card-left">
            <Dropdown
              anchor={
                <span className={"drop-down"}>{"Stärkere Konzentration"}</span>
              }
            />
          </div>
          <div className="drop-down-card-left-left">
            <Dropdown
              anchor={
                <span className={"drop-down"}>
                  {"Mehr Freude & gute Laune"}
                </span>
              }
            />
          </div>
          <div className="drop-down-card-left">
            <Dropdown
              anchor={<span className={"drop-down"}>{"Innere Ruhe"}</span>}
            />
          </div>
          <div className="drop-down-card-right">
            <Dropdown
              anchor={<span className={"drop-down"}>{"Kühlere Köpfe"}</span>}
            />
          </div>
        </Box>
        <Box
          component={Grid}
          item
          lg={4}
          md={4}
          display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
        >
          <img
            className="situation-image"
            src={"/assets/img/testcodes/Situation.png"}
          />
        </Box>
        <Box
          component={Grid}
          item
          lg={4}
          md={4}
          display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
        >
          <div className="drop-down-card-left">
            <Dropdown
              anchor={<span className={"drop-down"}>{"Stressabbau"}</span>}
            />
          </div>
          <div className="drop-down-card-right">
            <Dropdown
              anchor={<span className={"drop-down"}>{"Gesunder Schlaf"}</span>}
            />
          </div>
          <div className="drop-down-card-right-right">
            <Dropdown
              anchor={
                <span className={"drop-down"}>{"Höhere Motivation"}</span>
              }
            />
          </div>
          <div className="drop-down-card-right">
            <Dropdown
              anchor={
                <span className={"drop-down"}>{"Potentialentfaltung"}</span>
              }
            />
          </div>
          <div className="drop-down-card-left">
            <Dropdown
              anchor={
                <span className={"drop-down"}>{"Stärkeres Teamgefühl"}</span>
              }
            />
          </div>
        </Box>
      </Grid>
      <Grid
        container
        component="section"
        style={{ backgroundImage: `url("${blue}")`, backgroundSize: "cover" }}
      >
        <Grid item xs={12} sm={12} md={7} lg={7} className="list-section">
          <Grid item xs={12} sm={12} md={7} lg={7} className="list-group">
            <h2>
              Mental-Training überall und jederzeit - mit der Mindance App
            </h2>
            <ul>
              <li>
                Vielfalt an <b>wissenschaftlich</b> fundierten{" "}
                <b>Entspannungsübungen, Meditationen</b> und mentalen{" "}
                <b>Trainingskursen</b> für mehr Fokus, Zufriedenheit und Stärke
              </li>
              <li>
                Wissenschaftlich validierter Fragebogen zur{" "}
                <b>Messung des eigenen Mental Health Scores</b>
              </li>
              <li>
                <b>Individualisierter Trainingsplan</b> zur Verbesserung der
                mentalen Fitness in nur 8 Wochen
              </li>
              <li>
                <b>Fortschrittsmessung</b> zur eigenen Motivation und Reflektion
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} justify="flex-end">
          <img
            className="front-image-sitting"
            src={"/assets/img/testcodes/Sitting_frontimage.png"}
          />
        </Grid>
      </Grid>
      <Grid container component="section">
        <Grid container className="list-section">
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <img
              className="front-image"
              src={"/assets/img/testcodes/Infinity.png"}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} className="list-group">
            <h2>
              Nachhaltige Gesundheitsförderung durch menschliche und digitale
              Begleitung
            </h2>
            <p>
              <b>Digitale und menschliche Impulse</b> gehen bei Mindance und dem
              pme Familienservice Hand in Hand. Wie viel von was, entscheidet
              jede:r Nutzer:in für sich, um maximale Motivation zu wecken und am
              Leben zu halten - damit Gesundheitsförderung kein Strohfeuer
              bleibt.
            </p>
            Vielfältige Methoden- und Themenauswahl - für jeden das Passende
            dabei:
            <ul>
              <li className="list-in-list">
                zahlreiche <b>Experten-Vorträge</b>
              </li>
              <li className="list-in-list">
                <b>Einzel-</b> und <b>Gruppen-Coachings</b>
              </li>
              <li className="list-in-list">
                <b>eLearnings</b> und <b>Videos</b> on demand
              </li>
              <li className="list-in-list">
                (Live-) Online-<b>Bewegungskurse</b>
              </li>
              <li className="list-in-list">
                digitale <b>Gesundheitstage</b>
              </li>
            </ul>
            <p>
              Höchste <b>Qualität</b> durch festangestellte Coaches und
              Trainer:innen
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        component="section"
        style={{ backgroundImage: `url("${blue}")`, backgroundSize: "cover" }}
      >
        <h2>Warum entscheiden sich Unternehmen für Mindance?</h2>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="advantage-section"
        >
          <Grid
            item
            className="advantage-container"
            xs={12}
            sm={6}
            md={4}
            lg={4}
          >
            <img
              className="advantage-image"
              src="/assets/img/testcodes/advantage_absence.svg"
            />
            <p>
              <b>Fehlzeiten reduzieren</b> <br /> Gesündere und leistungsstarke
              Mitarbeiter durch weniger Stress
            </p>
          </Grid>
          <Grid
            item
            className="advantage-container"
            xs={12}
            sm={6}
            md={4}
            lg={4}
          >
            <img
              className="advantage-image"
              src="/assets/img/testcodes/advantage_motivation.svg"
            />
            <p>
              <b>Motivierte Mitarbeitende</b> <br />
              Leistungsstarke und zufriedene Mitarbeiter für eine starke
              Unternehmenskultur
            </p>
          </Grid>
          <Grid
            item
            className="advantage-container"
            xs={12}
            sm={12}
            md={4}
            lg={4}
          >
            <img
              className="advantage-image"
              src="/assets/img/testcodes/advantage_branding.svg"
            />
            <p>
              <b>Employer Branding</b> <br />
              Attraktivität der Employer Brand steigern und Fluktuation
              vorbeugen
            </p>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="advantage-section"
        >
          <Grid
            item
            className="advantage-container-filler"
            xs={0}
            sm={1}
            md={1}
            lg={1}
          />
          <Grid
            item
            className="advantage-container"
            xs={12}
            sm={5}
            md={5}
            lg={5}
          >
            <img
              className="advantage-image"
              src="/assets/img/testcodes/advantage_activation.svg"
            />
            <p>
              <b>Hohe Aktivierung</b> <br />
              Einzigartiges Blended Learning Konzept erzielt eine starke
              Aktivierung
            </p>
          </Grid>
          <Grid
            item
            className="advantage-container"
            xs={12}
            sm={5}
            md={5}
            lg={5}
          >
            <img
              className="advantage-image"
              src="/assets/img/testcodes/advantage_scientific.svg"
            />
            <p>
              <b>Wissenschaftlich</b> <br />
              Alle Inhalte sind wissenschaftlich fundiert
            </p>
          </Grid>
          <Grid
            item
            className="advantage-container-filler"
            xs={0}
            sm={1}
            md={1}
            lg={1}
          />
        </Grid>
        <img
          className="footer-image"
          src="/assets/img/testcodes/Lyingfooter.png"
        />
      </Grid>
    </Layout>
  )
}
